body {
  background-color: black;
  margin: 0;
  padding: 0;
  font-family: Arial, sans-serif; /* Optional, to give a default font styling */
}


.mandatory-items {
  padding: 3rem;
}

h4 {
  color: #fff;
  text-align: center;
}

.p {
  color: #fff;
}

ul {
  color: #fff;
}

.logo-bg {
  background-image: url('./assetts/DJFRACKINGLOGO.png');
  width: 12rem;
  height: 10rem;
  background-size: cover;
  display: flex;
  position: relative;
}

.logo-container {
  display: flex;
  justify-content: center; 
  align-items: center;
}

h2 {
  color: rgb(255, 255, 255);
  padding-bottom: 0.5rem;
  margin-bottom: .5rem;
  position: relative;  /* Added for the pseudo-element positioning */
  display: inline-block;  /* Ensures the h2 doesn't span the full width */
}

.underline {
  height: 1px;
  background: rgb(255, 255, 255);
  width: 90%;  
  margin: 0.5rem auto 1.5rem auto;  /* centers the line and provides space around it */
  max-width: 90rem;  /* maximum width, you can adjust as needed */
}



section {
  background-color: rgba(0, 0, 0, 0.7); /* Dark background with some transparency */
  border-radius: 8px; /* Rounded corners */
  padding: 3rem 3rem; /* Vertical and horizontal padding */
  margin: 2rem 0; /* Spacing between sections */
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.2); /* A subtle shadow for depth */
  position: relative;
  display: flex;         /* Convert the section to a flex container */
  flex-direction: column; /* Stack children vertically */
  align-items: center;   /* Center children horizontally */
  justify-content: center; /* Center children vertically */
}

.main-content {
}

.spotify-playlist {
  margin: 5rem auto; 
  width: 90%; /* or any other width that suits your layout */
  max-width: 600px; /* Optional, to prevent it from becoming too wide on large screens */
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.2); /* Optional shadow for depth */
}

.bookImage {
  height: 30rem;
  display: flex;
  align-content: center;
  
}

.bookCover {
  display: flex;
}

.services-section {
  background-color: rgba(0, 0, 0, 0.8); /* Dark background with transparency */
  padding: 2rem 3rem; /* Vertical and horizontal padding */
  border-radius: 8px; /* Rounded corners */
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.3); /* Shadow for depth */
  margin: 2rem 0; /* Spacing between sections */
}

.services-list {
  list-style-type: none;  /* Removes bullet points */
  padding: 0;  /* Removes default padding */
  margin: 1rem 0; /* Adds a bit of spacing on top and bottom */
  display: grid;
  grid-template-columns: 1fr 1fr;  /* Two columns */
  gap: 1rem;  /* Space between grid items */
}

.service-item {
  background-color: rgba(255, 255, 255, 0.1); /* Slightly light background */
  padding: 1rem; /* Padding inside each item */
  border-radius: 4px; /* Rounded corners */
  display: flex;
  justify-content: center; /* Centering the content horizontally */
  align-items: center; /* Centering the content vertically */
  transition: background-color 0.3s ease; /* Transition for hover effect */
  text-align: center;
}

.service-item span {
  color: #fff;  /* White color for text */
}

.service-item:hover {
  background-color: rgba(255, 255, 255, 0.2); /* Lighter background on hover */
}

.form-group {
  margin-bottom: 1rem;
}

label {
  display: block;
  margin-bottom: 0.5rem;
}

input[type="email"],
textarea {
  width: 100%;
  padding: 0.5rem;
  border: 1px solid #ccc;
  border-radius: 4px;
  font-size: 1rem;
  box-sizing: border-box;
}

button {
  padding: 0.5rem 1rem;
  background-color: #333;
  color: #fff;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

button:hover {
  background-color: #555;
}

label {
  color: #fff;
}

/* Website.css */
.about-section {
  display: flex;
  flex-direction: row;  /* Explicitly set the direction */
  align-items: flex-start;  /* Align to the start, so they don't stretch */
  justify-content: space-between;  /* Just for safe measure, to ensure they are spaced apart */
}


.left-div {
  width: 50%; 
  box-sizing: border-box;
}

.about-image img {
  height: auto;
  display: flex;
  margin: 0;
  padding: 0;
  border: none; 
  width: 100%;
}

.right-div {
  width: 50%;
  box-sizing: border-box;
  padding-left: 20px; 
  margin-right: 5rem;
  margin-left: 5rem;;
}



/* Styles for larger devices (from 769px and above) */
@media (max-width: 769px) {
    /* Styles from your current CSS go here */
    body {
        font-size: 18px;
    }

    .logo-bg {
        width: 12rem;
        height: 10rem;
    }

    h2::after {
        width: 60rem;
    }

    section {
        padding: 1rem 1rem;
    }

    .spotify-playlist {
        height: 352px;
    }

    .bookImage {
        height: 20rem;
    }

    .services-list {
        grid-template-columns: 1fr 1fr;
    }

    .about-section {
      display: block;
      width: 90%;
    }

    .right-div {
      margin-right: .5rem;
      margin-left: .5rem;
      width: 95%;
    }

    .left-div {
      width: 100%;
    }

}

.bonfire-image {
  display: flex;
  align-items: center;
  width: 15rem;
}

.bonfire-container {
  width: 100%;
  margin-top: 3rem;
  justify-content: center;
  display: flex;
  align-items: center;
}