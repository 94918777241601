.djpanel-container {
    background-image: url('./assetts/dark-brushed.jpg');
    background-size: cover;
    background-repeat: no-repeat;
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 20px;
  }
  
  .turntables {
    display: flex;
    justify-content: space-around;
    width: 100%;
    margin-bottom: 20px;
  }
  
  .controls {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
  }
  
  .buttons, .knobs, .sliders {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    gap: 10px;
    margin: 10px 0;
  }
  
  .control-button {
    background-color: #f39c12;
    border: none;
    color: #000;
    padding: 5px 10px;
    font-size: 12px;
    cursor: pointer;
    border-radius: 5px;
    margin: 5px;
  }
  
  .knob, .slider {
    width: 100px;
    margin: 5px;
  }
  
  .knob {
    transform: rotate(270deg);
  }
  
  
  .knob-container {
    position: relative;
    width: 50px;
    height: 25px; /* Change height to make it appear 3D */
    border-radius: 50%/25%; /* Change border-radius to create an oval shape */
    background-color: #444;
    margin: 20px; /* Increase margin to make space for labels */
    display: flex;
    justify-content: center;
    align-items: flex-end; /* Align to the bottom to make it appear 3D */
    perspective: 100px; /* Add perspective to create 3D effect */
  }
  
  .knob-indicator {
    position: absolute; /* Absolute positioning */
    width: 50px;
    height: 25px;
    background-color: #333; /* Darker color for the top */
    border-radius: 50%/25%;
    transform: rotateX(45deg); /* Rotation to create 3D effect */
  }
  
  .labels {
    font-size: 12px;
    color: #fff;
    text-align: center; /* Center align labels */
  }
  
  @media (max-width: 769px) {
    .turntables {
      display: block;
    }
  }