@font-face {
    font-family: 'SofiaPro';
    src: url('./sofiapro.otf') format('opentype');
    font-weight: normal;
    font-style: normal;
    font-display: swap;
  }
  
  @font-face {
    font-family: 'Avenir';
    src: url('./Avenir.otf') format('opentype');
    font-weight: normal;
    font-style: normal;
    font-display: swap;
  }
  
  @font-face {
    font-family: 'Tiempos';
    src: url('./Tiempos.otf') format('opentype');
    font-weight: normal;
    font-style: normal;
    font-display: swap;
  }
  
  @font-face {
    font-family: 'Roboto';
    src: url('https://fonts.googleapis.com/css2?family=Roboto:wght@400;700&display=swap');
    font-weight: normal;
    font-style: normal;
    font-display: swap;
  }
  