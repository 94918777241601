.turntable-container {
  width: 250px;
  height: 250px;
  position: relative;
  overflow: hidden;
  cursor: pointer;
  border-radius: 5px;
  padding: 10px;
}

.turntable {
  width: 200px;
  height: 200px;
  position: relative;
  border-radius: 50%;
  margin: 25px auto;
  background-color: #7f8c8d;
}


.record {
  width: 180px;
  height: 180px;
  background-color: #333;
  border-radius: 50%;
  position: absolute;
  left: 10px;
  top: 10px;
  transition: transform 0.5s ease-out; 
}

.label {
  width: 50px;
  height: 50px;
  background-color: #f39c12;
  border-radius: 50%;
  position: absolute;
  left: 65px;
  top: 65px;
}

.needle-arm {
  width: 100px;
  height: 5px;
  background-color: #c0392b;
  position: absolute;
  left: 5px;
  top: 5px;
  transform-origin: 0 0;
  transform: rotate(-25deg);
}

.rim {
  width: 200px;
  height: 200px;
  position: absolute;
  border-radius: 50%;
  border: 5px solid #bdc3c7; /* Existing rim */
  box-sizing: border-box;
  background: radial-gradient(circle at center, 
                               #bdc3c7 60%, 
                               #7f8c8d 75%, 
                               #2c3e50 90%, 
                               #1a2530 100%);
}

.record::before {
  content: "";
  position: absolute;
  width: 160px;
  height: 2px;
  background-color: #f39c12; /* Or any other color that stands out */
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%) rotate(0deg);
  transform-origin: center;
}

.record::after {
  content: "";
  position: absolute;
  width: 160px;
  height: 2px;
  background-color: #f39c12; /* Or any other color that stands out */
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%) rotate(90deg);
  transform-origin: center;
}